import React from 'react';
import './App.css';

const BlogCard = ({ title, image, views, date, author }) => {

  return (
    <div className="blog-card">
      <h1>BlogCard</h1>
    </div>
  );
}

export default BlogCard;

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BlogList from './BlogList';
import BlogCard from './BlogCard';

const App = () => {

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<BlogList />} />
                    <Route exact path="/BlogCard" element={<BlogCard />} />
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;

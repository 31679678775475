import BlogCard from './BlogCard';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const BlogList = () => {

  const location = useLocation(); // To get the current URL
  const navigate = useNavigate(); // To programmatically navigate
  const [count, setCount] = useState(0)

  useEffect(() => {

    console.log(location)
    const handleBackButton = (event) => {
      // Check if the current URL matches the specific URL
      if (location.pathname === '/') {
        event.preventDefault();  // Prevent the back navigation
        alert("Back navigation is disabled on this page!"); // Optional: Show a message
        navigate(0);  // Stay on the current page
      }
    };

    // Add popstate listener to handle back button
    window.addEventListener('popstate', handleBackButton);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [location, navigate, count]); // Depend on location and navigate

  // const handlePopState = () => {
  //   console.log(location.pathname)
  //   setCount(count + 1)
  // };

  // window.addEventListener('popstate', handlePopState);

  const handleBackButton = (event) => {
    // Check if the current URL matches the specific URL
    if (location.pathname === '/') {
      event.preventDefault();  // Prevent the back navigation
      alert("Back navigation is disabled on this page!"); // Optional: Show a message
      navigate(0);  // Stay on the current page
    }
  };

  window.addEventListener('popstate', handleBackButton);


  return (
    <div>
      <h2>Disable Back Button on Specific URL</h2>
      <p>You are on: {location.pathname}</p>
      <button onClick={() => navigate('/BlogCard')}>Navigate</button>
    </div>
  );
};

export default BlogList;
